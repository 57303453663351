
    /* header css */
    
    .HomeHeader .Header-bottom{
    background: #0b1023;
    }
    .commonHeader .Header-bottom{
    background: #fff;
    }
    
    .Header-top{
    background: #fff;
    }
    .call_info{
    color: #1c3563;
    text-align: right;
    margin: auto;
    }
    .call_info p{
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    }
    .call_info h1 {
    margin: 0px;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Libre Caslon Text', serif;
    }
    .call_info_padding{
    padding: 10px 0px;
    }
    .call_info h1 img{
    height: 36px;
    margin-right: 8px;
    }
    .Header-top.activeScrollHeader {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
    background: #fff;
    transition: .5s;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.15);
    }
    .HomeHeader .Header-bottom nav.navbar.custom_navbar ul li a.nav-link {
    color: rgba(255,255,255,.8);
    font-weight: 400;
    font-size: 13px;
    padding: 10px 2px;
    margin: 15px;
    text-transform: uppercase;
    }
    .HomeHeader .Header-bottom nav.navbar.custom_navbar ul li a.nav-link.active{ 
    border-bottom: 1px solid #ccc;
    }
    
    .commonHeader .Header-bottom nav.navbar.custom_navbar ul li a.nav-link {
    color: #1c3563;
    font-weight: 400;
    font-size: 13px;
    padding: 10px 2px;
    margin: 15px;
    text-transform: uppercase;
    }
    .commonHeader .Header-bottom.activeScrollHeader nav.navbar.custom_navbar ul li a.nav-link {
        color: rgba(255,255,255,.8);
    }
    .commonHeader .Header-bottom.activeScrollHeader nav.navbar.custom_navbar ul li a.nav-link.active {
    border-bottom: 1px solid rgba(255,255,255,.8);
    }
    .commonHeader .Header-bottom nav.navbar.custom_navbar ul li a.nav-link.active {
    border-bottom: 1px solid #1c3563;
    }

    @media screen and (max-width: 991px) {
        .navbar-toggler {
            padding: 0px;
            font-size: 1.25rem;
            line-height: 1;
            background-image: url('../img/icons/menu2.png');
            border: 1px solid transparent;
            border-radius: .25rem;
            background-repeat: no-repeat;
            background-color: transparent;
            outline: 0px !important;
        }
        .commonHeader .Header-bottom .navbar-toggler {
            background-image: url('../img/icons/menu1.png');
        }
        .commonHeader .Header-bottom.activeScrollHeader .navbar-toggler {
            background-image: url('../img/icons/menu2.png');
        }

        .navbar {
            width: 100%;
        }
        .navbar-nav li a{
            text-align: center;
        }
        .HomeHeader .Header-bottom nav.navbar.custom_navbar ul li a.nav-link.active {
            border-bottom: none !important;
            color: #c18f50;
        }
        .commonHeader .Header-bottom nav.navbar.custom_navbar ul li a.nav-link.active {
            border-bottom: none !important;
            color: #c18f50;
            }
        .HomeHeader .Header-bottom nav.navbar.custom_navbar ul li a.nav-link {
            color: rgba(255,255,255,.8);
            font-weight: 400;
            font-size: 13px;
            padding: 10px 2px;
            margin: 10px;
            text-transform: uppercase;
        }
        
        .call_info_padding {
            padding: 10px 0px;
            flex-wrap: nowrap;
        }
        .call_info h1 {
            margin: 0px;
            font-size: 15px;
            font-weight: 700;
            font-family: 'Libre Caslon Text', serif;
        }
        .call_info h1 img {
            width: auto;
            height: 20px;
            margin-right: 8px;
        }
    }
    @media screen and (max-width: 441px) {
        .call_info_padding img{
            width: 100%;
        }
    }