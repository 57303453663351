*{
margin:0px;
padding: 0px;
box-sizing: border-box;
}
body{
font-family: 'Roboto', sans-serif;
}
.mrb0{
    margin-bottom: 0px;
}
.mr-bottom-80
{
    margin-bottom: 80px;
}
.mr-bottom-50
{
    margin-bottom: 50px;
}
.mr-top-50
{
    margin-top: 50px;
}

/* home banner */

.Homebanner{
background: #0b1023;
}
.commonPg{
padding: 50px;
}

@media screen and (max-width: 991px) { 
.commonPg {
    padding: 30px 0px;
}
}
/* banner css */
.inner-home-banner{
    width: 650px;
    height: 200px;
}
.inner-home-banner img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.height60vh{
min-height: 60vh;
}

.headlines h2 {
color: rgba(255,255,255,.8);
font-weight: 300;
font-size: 30px;
padding-bottom: 12px;
border-bottom: 1px solid rgba(255,255,255,.8);
margin-bottom: 20px;
}

.headlines h1 {
color: #fff;
font-weight: 500;
font-size: 35px;
text-transform: uppercase;
margin-bottom: 6px;
line-height: 35px;
}
form.formcard {
    background: #fff;
    border-radius: 6px;
    padding: 25px;
    margin-right: 15px;
    margin-left: 15px;
}
.advertise_head {
font-size: 20px;
text-align: center;
font-weight: 500;
color: #1c3563;
margin-bottom: 25px;
}
.input_container {   
border: 1px solid #e9e1dc;
background: #fbfbfb;
height: 45px;
box-shadow: none !important;
outline: 0px;
padding: 10px;
}
.input_container select {
color: #495057;
font-size: 14px;
border: 0px;
background: #fbfbfb;
height: 100%;
font-weight: 400;
box-shadow: none !important;
outline: 0px;
width: 100%;
}
.input_container select:focus{
background: transparent;
}
.submit_btn {
border: 0px;
outline: 0px;
background-image: linear-gradient(to right, #d7ba6c, #d4b769cc, #d7ba6c);
padding: 10px 30px;
font-size: 18px;
font-weight: 600;
text-transform: uppercase;
color: #0b1023;
border-radius: 5px;
margin-top: 15px;
outline: 0px !important;
webkit-transition: all 200ms ease;
    transition: all 200ms ease;
}
.submit_btn:hover{
    webkit-transform: translate(0px, -10px);
    -ms-transform: translate(0px, -10px);
    transform: translate(0px, -10px);
}

@media screen and (max-width: 991px) { 
    form.formcard {
        background: #fff;
        border-radius: 6px;
        padding: 25px;
        margin-right: 0px;
        margin-left: 0px;
    }
    .advertise_head {
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        color: #1c3563;
        margin-bottom: 25px;
    }
    .headlines h2 {
        color: rgba(255,255,255,.8);
        font-weight: 300;
        font-size: 22px;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(255,255,255,.8);
        margin-bottom: 20px;
    }
    .headlines h1 {
        color: #fff;
        font-weight: 500;
        font-size: 22px;
        text-transform: uppercase;
        margin-bottom: 0px;
        line-height: 35px;
    }
    .inner-home-banner {
        width: 100%;
        height: 200px;
    }
}
@media screen and (max-width: 767px) { 
    .headlines{
        margin-bottom: 30px;
        margin-top: 10px;
    }
    .headlines h2{
        width: 75%;
    }
}

/* logo slider css */

.logoSlider {
background: #1c3563;
padding: 20px 0px;
width: 100%;
}

.logoSlider .logo_images{
    height: 75px;
    margin: auto;
    display: flex;
}
.obituary_slider2 .logo_images {
    height: 100px;
    margin: auto;
    display: flex;
}

@media screen and (max-width: 991px) { 
.logoSlider {
    background: #1c3563;
    padding: 0px;
    width: 100%;
}
}
/* product section */

.productLeftSection1 img {
height: 150px;
margin-right: 15px;
}

.headproducts h1:first-child {
font-size: 33px;
text-transform: uppercase;
margin-bottom: 10px;
line-height: 25px;
font-weight: 600;
}

.headproducts h1:last-child {
font-size: 36px;
text-transform: uppercase;
margin-bottom: 0px;
line-height: 28px;
font-weight: 600;
letter-spacing: 1px;
}

.productLeftSection1{
display: flex;
align-items: flex-end;
}

.products_logo1 img , .products_logo2 img{
margin: 15px auto;
box-shadow: 0px 0px 3px #ccc;
} 

.productLeftSection2{
display: flex;
margin-top: 30px;
}
.prod h4{
font-size: 18px;
font-weight: 500;
text-transform: capitalize;
margin-bottom: 5px;
line-height: 20px;
}
.prod p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0px;
    margin-right: 15px;
}
@media screen and (max-width: 991px) { 
    .productLeftSection1 img {
        height: 100px;
        margin-right: 15px;
    }
    .headproducts h1:first-child {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 5px;
        line-height: 20px;
        font-weight: 600;
    }
    .headproducts h1:last-child {
        font-size: 22px;
        text-transform: uppercase;
        margin-bottom: 0px;
        line-height: 22px;
        font-weight: 600;
        letter-spacing: 1px;
    }
    .prod h4 {
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 5px;
        line-height: 20px;
    }
    .prod p {
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 0px;
    }
    .productLeftSection2 {
        display: flex;
        margin-top: 25px;
        margin-bottom: 15px;
    }
}

/* testimonial section */

.testimonial_section{
background: #fbf8f5;
}
.testimonial_slider {
position: relative;
min-height: 300px;
padding: 5px;
}
.testi_image {
    height: 150px;
    z-index: 999;
    margin-left: 25px;
    border-radius: 50%;
    width: 150px;
    object-fit: contain;
    background: #fff;
    box-shadow: 0px 3px 12px #f5f5f5;
}
.testi_content_slide {
padding: 30px;
background: #fff;
text-align: center;
z-index: -1;
margin-top: -75px;
height: 200px;
border-radius: 10px;
box-shadow: 0px 0px 5px #ccc;
}
.testi_content_slide p {
    margin-top: 60px;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.profile_sec {
display: flex;
align-items: flex-end;
}
.testi_content{
margin-left: 20px;
}
.testi_content h6{
color: #212121;
font-size: 16px;
margin-bottom: 2px;
}
.testi_content span{
color: #212121;
font-size: 14px;
font-weight: 400;
}

.testimonial_text{
    color: #707070;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    letter-spacing: 1px;
}
.commonHead {
display: flex;
align-items: center;
margin-bottom: 15px;
}
.commonHead h2{
color: #212121;
font-size: 28px;
font-weight: 600;
margin-bottom: 0px;
}
.dash {
width: 40px;
height: 3px;
background: #663f08;
margin-left: 15px;
}
@media screen and (max-width: 991px) { 
.commonHead h2 {
    color: #212121;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
}
.testimonial_text {
    color: #707070;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    letter-spacing: 0px;
}
.testi_content_slide {
    padding: 25px;
    background: #fff;
    text-align: center;
    z-index: -1;
    margin-top: -75px;
    height: 185px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ccc;
}
.testi_content_slide p {
    margin-top: 60px;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
}
/* about section */

.aboutBanner{
background-image: url('../img/about.png');
background-position: center;
background-repeat: no-repeat;
background-size: contain;
}
.about_pera{
margin-right: 50px;
}
.about_pera h2{
font-size: 28px;
color: #212121;
font-weight: 500;
margin-bottom: 10px;
}
.about_pera p
{
    color: #707070;
    font-size: 16px;
    word-spacing: 1px;
    line-height: 25px;
    margin-bottom: 0px;
}

@media screen and (max-width: 991px) { 
    .about_pera h2 {
        font-size: 22px;
        color: #212121;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .about_pera p {
        color: #707070;
        font-size: 14px;
        word-spacing: 0px;
        line-height: 25px;
        margin-bottom: 0px;
    }
    .about_pera {
        margin-right: 0px;
    }
.aboutBanner {
    height: 300px;
    margin-top: 30px;
}
}

/* newspaper section css */
.newspaperSection{
background: #fbf8f5;
}
#tabs {
margin: 15px 0px;
}
#tabs li a:hover, #tabs li a.nav-link.active {
background: #663f08;
color: #fff;
border: 0;
box-shadow: 0px 6px 16px rgb(0 0 0 / 33%);
}
#tabs li a {
border-radius: 5.25rem;
color: #9a9a9a;
display: inline-block;
padding: .6rem 2rem;
margin-bottom: 0.5rem;
margin-right: 20px;
font-weight: 500;
text-transform: capitalize;
font-size: 18px;
transition: all .3s ease-in-out;
border: 0;
background: #fff;
box-shadow: 0px 3px 3px #cccccc3d;
}
#tabs li a:hover, #tabs li a.nav-link.active {
background: #663f08;
color: #fff;
border: 0;
}
.NewspaperList {
    color: #313131;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 15px;
    cursor: pointer;
}
.newspaperList.fix_list h5 {
    width: 265px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
    text-transform: capitalize;
}
@media screen and (max-width: 991px) {
    #tabs {
        margin: 15px 0px;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }
    #tabs li a {
        border-radius: 5.25rem;
        color: #9a9a9a;
        display: inline-block;
        padding: .4rem 1.5rem;
        margin-bottom: 15px;
        margin-right: 20px;
        font-weight: 500;
        text-transform: capitalize;
        font-size: 14px;
        transition: all .3s ease-in-out;
        border: 0;
        background: #fff;
        box-shadow: 0px 3px 3px #cccccc3d;
        white-space: nowrap;
    }
    .NewspaperList {
        color: #313131;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 400;
        margin-bottom: 15px;
        cursor: pointer;
    }
}


/* enquiry form css */

.enquiryForm{ 
background-image: url('../img/enquiryBg.png');
background-repeat: no-repeat;
background-size: 100% 100%;
background-position: center;
margin: 20px 0px;
}
.enquiryForm img {
height: 550px;
width: 100%;
object-fit: fill;
box-shadow: 0px 3px 40px #00000046;;
}
.enquiry_fields{
display: grid;
grid-template-columns: auto auto;
}
.custom_fields {
    margin-bottom: 20px;
}
.form_enquiry label{
color: #fff;
font-size: 15px;
text-transform: capitalize;
font-weight: 500;
}
.enquiryHead {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.enquiryHead h2 {
color: #ffffff;
font-size: 28px;
font-weight: 600;
margin-bottom: 0px;
}
.enquiryHead .dash {
width: 40px;
height: 3px;
background: #ffffff;
margin-left: 15px;
}
.form_enquiry button{
margin-top: 15px;
border: 1px solid #fff;
background: transparent;
color: #fff;
padding: 10px;
width: 200px;
font-weight: 500;
text-transform: uppercase;
font-size: 15px;
outline: 0px;
transition: all 200ms ease;
}
.form_enquiry button:hover {
    webkit-transform: translate(0px, -10px);
    transform: translate(0px, -10px);
}
.form_enquiry input, .form_enquiry select, .form_enquiry textarea {
    color: #495057;
    font-size: 14px;
    border: 0px;
    background: #fbfbfb;
    min-height: 45px;
    font-weight: 400;
    transition: all 400ms ease-in-out;
    box-shadow: none !important;
    outline: 0px;
    padding: 10px 15px;
    border-radius: 5px;
    width: 100%;
}
@media screen and (max-width: 991px) { 
    .enquiryForm {
        background-size: cover;
        margin: 0px;
        padding: 50px 0px;
    }
    .enquiryForm img{
        display: none;
    }
}
@media screen and (max-width: 767px){
    .enquiryHead {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
    }
    .enquiryHead h2 {
        color: #ffffff;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
    }
    .custom_fields {
        margin-bottom: 15px;
    }
    .form_enquiry button {
        margin-top: 15px;
        border: 1px solid #fff;
        background: transparent;
        color: #fff;
        padding: 10px;
        width: 160px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
        outline: 0px;
    }
}
/* footer css */

.footer1{
background-image: url('../img/footer.png');
background-repeat: no-repeat;
background-size: cover;
position: relative;
display: flex;

}
.aboutSidebar {
    width: 22%;
    padding: 20px;
    background: #663f08;
    position: absolute;
    top: -25px;
    left: 0px;
    height: 525px;
}
.footerRight {
    width: 78%;
    margin-left: auto;
    height: 500px;
}
.contact_info {
display: flex;
margin-bottom: 60px;
margin-top: 30px;
}
.contactdiv img{
height: 20px;
margin-right: 15px;
}
.contactdiv {
display: flex;
padding: 0px 50px;
}
.cont_details h2{
color: #fff;
font-weight: 600;
font-size: 18px;
margin-bottom: 5px;
}
.cont_details p{
color: rgba(255,255,255,0.5);
font-size: 14px;
font-weight: 400;
margin-bottom: 0px;
}
.border_right{
border-right: 2px solid rgba(255,255,255,0.5);
}
.innerPagesLinks{
display: grid;
grid-template-columns: auto auto auto;
}
.innerPagesLinks ul li {
margin-bottom: 15px;
list-style: none;
}
.innerPagesLinks ul li a {
color: rgba(255,255,255,0.8) !important;
font-size: 14px;
font-weight: 500;
text-transform: uppercase;
padding: 0px 50px;
text-decoration: none;
cursor: pointer;
}

.address_detail{
padding: 0px 50px;
color: rgba(255,255,255,0.8);
}
.address_detail h3{
font-size: 20px;
text-transform: capitalize;
margin-bottom: 10px;
font-weight: 500;
}
.address_detail h2 {
font-size: 25px;
font-weight: 500;
margin-bottom: 7px;
text-transform: uppercase;
}
.address_detail p{
margin-bottom: 2px;
font-size: 15px;
font-weight: 300;
}
.footerlogo {
    background: transparent;
    margin-bottom: 20px;
}
.footerlogo img{
height: 60px;
}
.aboutSidebar h2{
color: #fff;
font-size: 20px;
text-transform: capitalize;
font-weight: 500;
}
.aboutSidebar p {
font-weight: 300;
font-size: 14px;
color: rgba(255,255,255,0.8);
margin-bottom: 20px;
line-height: 26px;
}
.social_ions {
display: flex;
justify-content: start;
margin-bottom: 10px;
}
.social_ions img {
height: 40px;
cursor: pointer;
margin-right: 20px;
}
.footer2{
background: #000;
}
.bottomFooter {
display: flex;
justify-content: space-around;
padding: 20px;
}
.footerparts {
display: flex;
align-items: center;
width: 25%;
justify-content: center;
}
.footerparts img{
height: 25px;
margin-right: 15px;
}
.footerparts h5 {
color: #fff;
font-weight: 400;
font-size: 15px;
text-transform: capitalize;
margin-bottom: 0px;
}
.footerparts p{
color: rgba(255,255,255,0.8);
font-size: 13px;
font-weight: 300;
margin: 0px;
}
@media screen and (max-width: 991px) { 
.footer1 {
    display: flex;
    flex-wrap: wrap;
}
.aboutSidebar {
    width: 100%;
    padding: 20px;
    background: #663f08;
    position: unset;
    height: auto;
}
.footerRight {
    width: 100%;
    margin-left: 0px;
    height: auto;
}
.cont_details h2 {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
}
.cont_details p {
    color: rgba(255,255,255,0.5);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
}

}
@media screen and (max-width: 767px) { 
    .footerRight {
        width: 100%;
        margin-left: 0px;
        height: auto;
        margin: auto 20px;
    }
.contact_info {
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-direction: column;
}
.contactdiv {
    display: flex;
    padding: 15px 0px;
}
.border_right {
    border-right: none;
}
.innerPagesLinks {
    display: grid;
    grid-template-columns: auto;
}
.innerPagesLinks ul{
    margin-bottom: 0px;
}
.innerPagesLinks ul li a {
    color: rgba(255,255,255,0.8) !important;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0px;
    text-decoration: none;
    cursor: pointer;
}
.address_detail {
    padding: 0px 0px;
    color: rgba(255,255,255,0.8);
    margin: 20px 0px;
}
.address_detail h3 {
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-weight: 500;
}
.address_detail p {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 300;
}
.address_detail h2 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.bottomFooter {
    display: flex;
    justify-content: start;
    padding: 20px;
    flex-direction: column;
}
.footerparts img {
    height: 20px;
    margin-right: 10px;
}
.footerparts {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: start;
    margin-bottom: 15px;
}

}
/* obituary section  */

.obituary_section{
background-image: url('../img/inner_banner.png'); 
background-position: center;
background-repeat: no-repeat;
background-size: cover;
padding: 100px;
}

.obituary_side_img{
background-image: url('../img/cards.png');
background-position: center;
background-repeat: no-repeat;
height: 400px;
background-size: contain;
}
.obituary_side_img.remembrance
{
    background-image: url('../img/card4.png');
}

/* obituary slider */

.obituary_slider {
    padding: 80px 0px;
    width: 100%;
}
.obituary_slider2 {
    padding: 50px 15px;
}
.obituary_slider .logo_images img{
    width : 72%;
}

/* requirement section */

.mainBg{
    background-image: url('../img/mainBg.png');
    background-position: center;
    /* background-repeat: no-repeat; */
    height: auto;
    width: 100%;
}
.commonHeadLight {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.commonHeadLight h2 {
    color: #ffffff;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px;
}
.commonHeadLight .dash {
    width: 40px;
    height: 3px;
    background: #ffffff;
    margin-left: 15px;
}
.bg_blur {
    background: rgba(0,0,0,0.46);
    padding: 30px 0px;
    backdrop-filter: blur(10px);
}
.requirement_section{
    background-image: url('../img/newspaper_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 30px;
}
.requirement_section img{
    height: 300px;
}
.requirement_detail h4 {
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 13px;
}

/* remembrance section */

.requirement_section .img_custom_height img{
    height: 250px;
}
.img_custom_height h3{
    color: #fff;
    margin-top: 15px;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
}
/* contact us css */

.contact_box {
    background-image: url('../img/contact_us.png') ;
    border-radius: 5px;
    padding: 20px 0px;
    background-position: center;
    background-size: 100% 100%;
}
.contact_data {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 10px 25px;
}
.contact_data i{
    font-size: 22px;
    margin-right: 20px;
}
.address_field h5 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 0px;
}
.address_field p{
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 300;
}

.contact_box_head{
    width: 150px;
    background: #fff;
    text-align: center;
    line-height: 33px;
    height: 33px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    border-radius: 0px 10px 10px 0px;
}
.contact-form {
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    padding: 20px;
}

.contact-form input, .contact-form select, .contact-form textarea{
    color: #495057;
    font-size: 14px;
    border: 0px;
    background: #fbfbfb;
    min-height: 45px;
    font-weight: 400;
    transition: all 400ms ease-in-out;
    outline: 0px;
    padding: 10px 15px;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 15%);
}
.contact-form label{
    font-size: 16px;
    font-weight: 500;
    color: #515151;
    margin-bottom: 10px;
}
.contact-form button{
    border: 0px;
    outline: 0px !important;
    background: #663f08;
    padding: 0px 30px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    height: 42px;
    width: 150px;
    border: 2px solid #663f08;
}

.address_map iframe{
    width: 100%;
    height: 500px;
    margin-top: 50px;
    border: 0px;
    box-shadow: 0px 3px 5px #ccc;
}

/* faq css */

.card-faq{
    border: 0;
    box-shadow: 0 5px 7px hsl(0deg 0% 80% / 79%);
    margin: 15px 0;
}
.faq_card_design {
    background: #f5f5f5;
    color: #707070;
}
.faq_card_design h5 {
    margin-bottom: 0px;
    font-size: 18px;
    cursor: pointer;
}
.custom_card_body{
    font-size: 15px;
    font-weight: 400;
    color: #212121;
}

/* steps css */
.stepBG{
    /* background-image: url('../img/step_1.png') ; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 65px;
}
.stepsHead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.stepsHead h2{
    font-size: 22px;
    font-weight: 600;
    margin: 15px;
    width: 215px;
    text-align: center;
    color: #515151;
    text-decoration: none !important;
}
.stepsHead h2.active {
    color: #755222;
}
.newspaperListSec{
    margin-top: 50px;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    padding: 20px;
}
.newspaperListSec h2{
    font-size: 22px;
    font-weight: 600;
    color: #515151;
    margin-bottom: 15px;
}
.newspaperList{
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.newspaperList h5 {
    font-size: 18px;
    font-weight: 500;
    color: #515151;
    margin-bottom: 0px;
    
}
.circulation_area{
    display: flex;
    justify-content: center;
}
.circulation_area span{
 padding-right: 5px;
}
.newsRight{
    display: flex;
    align-items: center;
}
.newsPrice span {
    font-size: 15px;
    font-weight: 600;
    color: #313131;
    text-transform: capitalize;
}
.newsPrice p{
    margin-bottom: 0px;
    color: #515151;
    font-weight: 500;
}
.newsPrice p.location_area_p {
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
    width: 265px;
    margin-bottom: 0px;
    color: #515151;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}
.newsRight button {
    border: 0px;
    outline: 0px !important;
    background: #663f08;
    padding: 0px 30px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    height: 42px;
    margin-left: 25px;
    border: 2px solid #663f08;
}
.format_design{
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}
.format_design img {
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
}
.classified_ad h3{
    font-size: 20px;
    font-weight: 500;
    color: #212121;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.format_design button{
    border: 0px;
    outline: 0px !important;
    background: #663f08;
    padding: 0px 30px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    height: 42px;
    border: 2px solid #663f08;
}
.color_mode {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 30px;
}
.color_mode h3{
    margin: 0px;
    color: #313131;
    font-size: 20px;
    font-weight: 600;
}
.radio_btns{
    margin-left: 15px;
}
.radio_btns label{
    color: #313131;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
}
.radio_btns input{
    margin: 0px 12px;
}
.date_payment_btns{
    text-align: center;
    margin-top: 30px;
}
.newsRight h4{
    margin: 0px;
    color: #663f08;
    font-weight: 600;
    font-size: 20px;
}
.back_btn{
    border: 1px solid #663f08;
    outline: 0px !important;
    background: #fff;
    padding: 0px 30px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: #663f08;
    border-radius: 5px;
    height: 42px;
    margin: 0px 10px;
}
.upload_design_btn{
    border: 1px solid #663f08;
    outline: 0px !important;
    background: #663f08;
    padding: 0px 30px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    height: 42px;
    margin: 0px 10px;
    border: 2px solid #663f08;
}
.date_section{
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    text-align: center;
}
.date_section .react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    border: none;
    line-height: normal;
    border-radius: 8px;
    font-family: 'Roboto';
}
.react-calendar button {
    border: 0;
    outline: 0px;
    font-size: 14px;
    font-weight: 500;
}
.react-calendar__tile--now {
    background: #eec284;
}
.react-calendar__tile--active {
    background: #663f08 !important;
    color: white;
}
.date_sectionLeft{
    padding: 20px;
}
.date_payment_head {
    display: flex;
    margin-bottom: 20px;
    justify-content: start;
}
.date_payment_head img {
    height: 30px;
    margin-right: 20px;
}
.date_payment_head h3{
    margin: 0px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 1px;
    line-height: 28px;
    color: #212121;
}
.date_section h5 {
    margin-bottom: 15px;
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 28px;
    color: #663f08;
    text-align: left;
}
.inc_dec_add {
    display: flex;
    justify-content: start;
}
.inc_dec_add button {
    box-shadow: 0px 2px 5px #663f088a;
    border: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 22px;
    line-height: 40px;
    color: #663f08;
    outline: 0px;
}
.ad_value{
    box-shadow: 0px 2px 5px #663f088a;
    border: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 40px;
    color: #212121;
    outline: 0px;
    margin: 0px 15px;
}
.selected_dates {
    display: grid;
    grid-template-columns: auto auto auto;
}
.selected_dates button {
    background: #d69a46;
    border: 0px;
    outline: 0px;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    line-height: 27px;
    height: 35px;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 500;
    width: 140px;
}
.uploading_section {
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    text-align: center;
}
.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    text-align: center;
}
.wrapper h3 {
    font-size: 18px;
    font-weight: 600;
    color: #414141;
    text-transform: capitalize;
    margin-bottom: 20px;
    position: absolute;
    left: 30px;
    top: 15px;
}
  .wrapper .file-upload {
    height: 210px;
    width: 230px;
    border-radius: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-image: url('../img/icons/upload_img.png');
    background-size: contain;
    color: #FFFFFF;
    background-position: center;
    background-repeat: no-repeat;
  }
  .wrapper .file-upload input[type=file] {
    height: 210px;
    width: 230px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .uploading_notes{
    padding: 20px;
    }
  .uploading_notes h2{
    color: #212121;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
}
.uploading_notes p{
    margin-bottom: 10px;
    font-size: 14px;
    text-align: left;
    color: #515151;
    font-weight: 400;
}
.uploading_notes h3 {
    color: #8f5818;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
    letter-spacing: 1px;
    margin-top: 15px;
}
.uploading_notes h5 {
    color: #515151;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
}
.cat_text_content {
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    text-align: center;
    margin-top: 15px;
}
.combine_ad{
    display: flex;
    align-items: center;
}
.ad_input_bg {
    background: #f1f0f0;
    margin: 0px;
    width: 250px;
    height: 75px;
    white-space: nowrap;
    padding: 20px 0px;
}
.summary_inputs {
    width: 100%;
    height: 75px;
}
.border_upper{
    border-bottom: 1px solid #ccc;
}
.summary_inputs input{
    border: 0;
    font-size: 16px;
    font-weight: 400;
    outline: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.ad_input_bg h3{
    color: #515151;
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
}
.ad_input_bg p{
    font-size: 12px;
    font-weight: 500;
    color: #663f08;
    margin: 0px;
}
.summary_text{
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    margin-top: -10px;
}
.instruction_box {
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    text-align: center;
    margin-top: 50px;
    padding: 15px;
}
.instruction_box h3{
    margin: 0px;
    font-size: 16px;
    text-align: left;
    font-weight: 500;
    color: #515151;
}
.instruction_box textarea {
    width: 100%;
    border: 0px;
    outline: 0px;
    font-size: 15px;
    font-weight: 400;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    margin-top: 15px;
}
.custom_table {
    background: #fff;
    /* margin-top: 50px; */
    text-align: center;
}
.payment-sec{
    margin-top: 50px;   
}
.custom_table thead{
    background: #663f08;
    color: #fff;
}
.custom_table tbody th, .custom_table tbody tr td{
    font-weight: 500;
    font-size: 16px;
    color: #515151;
}
.cost_field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-right: 25px;
    margin-left: 25px;
}
.cost_field span{
    font-size: 16px;
    font-weight: 500;
    color: #515151;
}
.payment_box {
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    text-align: center;
    margin-top: 40px;
    padding: 20px;
}
.cost-box {
    height: 450px;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    text-align: center;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #663f08;
}
.cost-box-div h4{
    color: #212121;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
}
.cost-box-div h2{
    font-size: 40px;
    color: #663f08;
    margin: 0px;
}
.make_payment{
    margin-top: 30px;
    margin-bottom: 10px;
}
.make_payment button{
    border: 1px solid #663f08;
    outline: 0px !important;
    background: #663f08;
    padding: 0px 30px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    height: 42px;
}
.cost-box-div span{
    color: #515151;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
}
.subcat_pref_box{
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-top: 50px;
}
.subcat_pref_box h2{
    font-size: 20px;
    font-weight: 500;
    color: #515151;
    margin-bottom: 15px;
}
.subcat_pref_box textarea {
    width: 100%;
    border: 0px;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #515151;
    outline: 0px;
}
.matter-cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}
/* .matter-cost h4{
    font-size: 16px;
    margin-bottom: 0px;
    color: #212121;
} */
.matter-cost h5{
    font-size: 16px;
    margin-bottom: 0px;
    color: #515151;
    font-weight: 500;
}
.matter-cost h5 span {
    font-size: 16px;
    margin-bottom: 0px;
    color: #663f08;
    font-weight: 500;
}
.ita_p{
font-style: italic;
}
.note_matter{
    margin: 0px;
    color: #515151;
    font-style: italic;
    font-size: 16px;
    font-weight: 500;
}
.preview_container{
    margin-top: 50px;
    border: 1px solid #ccc;
}
.preview_header{
    background: #663f08;
    color: #fff;
    text-align: center;
}
.preview_header h2{
    font-size: 18px;
    padding: 10px;
    margin: 0px;
}
.preview-box {
    background: #fff;
    width: 100%;
    min-height: 150px;
    padding: 5px 0px;
}
.preview-box p {
    font-size: 13px;
    line-height: 1.6;
    margin: 0px;
    color: #212121;
    font-weight: 400;
    padding: 0px 10px;
    word-break: break-all;
}
.bottom_note_preview{
    background: #f5f5f5;
}
.bottom_note_preview p {
    font-size: 13px;
    line-height: 17px;
    margin: 0px;
    color: #8a8a8a;
    font-weight: 400;
    padding: 10px;
    background: #f5f5f5;
}
.screen_border_div{
    display: flex;
}
.borderdiv1{
    margin-right: 20px;
}
.borderdiv1 label{
    font-size: 16px;
    color: #212121;
    font-weight: 500;
    margin: 0px;
    text-transform: capitalize;
}
.borderdiv1 input{
    margin: 0px 10px;
}
.select_bg {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.select_bg label {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
}
.bg_colors {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    /* border: 2px solid #ccc; */
    margin-right: 30px;
    cursor: pointer;
   
}
.screen-border{
    border: 1px solid#663f08;    
}
.no_border{
    border: none;
}
.bg_colors.active{
    border: 2px solid#663f08;  
}

.design_block_section{
    width:100%;
    height:100%;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
}
.block_section.s_width{
    /* background-image: url('../img/color_images/classified_frame.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95% 95%;
    width: 55%; */
    /* min-height: 350px; */
    margin: auto;
    text-align: center;
    /* height: 100%; */
    position: relative;
}
.border_corners {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    border: 1px double #b76e31;
    border-width: 4px;
    border-style: double;
}
.block_section img.corner1{
    width: 35px;
    height: auto;
    position: absolute;
    top: 0px;
    left: 0px;
}
.block_section img.corner2{
    width: 35px;
    height: auto;
    position: absolute;
    top: 0px;
    right: 0px;
}
.block_section img.corner3{
    width: 35px;
    height: auto;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.block_section img.corner4{
    width: 35px;
    height: auto;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.block_section.l_width{
    background-image: url('../img/color_images/classified_frame.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95% 95%;
    width: 70%;
    /* height: 100%; */
    margin: auto;
    text-align: center;
}
.block_section h2 {
    font-size: 15px;
    color: #663f08;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    margin: 5px 7px;
    padding-top: 8px;
}
.block_section2 h2 {
    font-size: 15px;
    color: #663f08;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    margin: 5px 7px;
    margin-top: 40px;
}
.block_section2 .blockui2 {
    width: 80%;
    margin: auto;
    padding-bottom: 40px;
}
.block_section p, .block_section2 p {
    font-size: 14px;
    margin-bottom: 5px;
    color: #212121;
    text-align: center;
    line-height: 1.2;
}
.codex-editor__redactor{
    padding-bottom: 0px !important;
}
.codex-editor--narrow .codex-editor__redactor {
    margin-right: 0px;
}
.codex-editor--narrow .ce-block--focused {
    margin-right: 0px;
    padding-right: 0px; 
}
.block_section2.width12{
    /* background-image: url('../img/color_images/displayad_frame.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95% 95%;
    width: 100%;
    /* min-height: 350px;
    max-height: 680px; */
    margin: auto;
    text-align: center;
}
.block_section2.width8{
    /* background-image: url('../img/color_images/displayad_frame.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95% 95%;
    width: 75%;
    /* min-height: 350px;
    max-height: 680px; */
    margin: auto;
    text-align: center;
}
.block_section2.width4{
    /* background-image: url('../img/color_images/displayad_frame.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95% 95%;
    width: 55%;
    /* min-height: 350px;
    max-height: 680px; */
    margin: auto;
    text-align: center;
}
.blockui1{
    width: 80%;
    margin: auto;
}
.blockui2 {
    width: 80%;
    margin: auto;
    padding-bottom: 8px;
}
.block_section img {
    width: 70%;
    height: 70px;
    object-fit: cover;
}
.block_section2 img {
    width: 70%;
    height: 80px;
    object-fit: cover;
}
.h4_classi {
    font-size: 15px;
    color: #663f08;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 5px;
}
.h5_classi {
    font-size: 14px;
    color: #212121;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 5px;
}
.p_classi{
    font-size: 15px;
    color: #212121;
    text-align: center;
    margin-bottom: 0px;
    line-height: 18px;
    font-weight: 400;
}
hr.edit {
    border-color: #663f08;
    margin: 10px auto;
}
.ita_p{
    font-weight: 400;
    font-style: italic;
}
.block_ui span{
    font-size: 15px;
    font-weight: 400;
}
/* calender css */
.rmdp-week-day {
    cursor: default;
    color: #663f08;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
}
.rmdp-arrow {
    border: solid #663f08;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    height: 8px;
    width: 8px;
    margin-top: 6px;
}
.rmdp-arrow-container:hover {
    background-color: #663f08;
    box-shadow: 0 0 3px #8798ad;
}
@media (max-width: 450px), (max-height: 450px){
.rmdp-day, .rmdp-week-day {
    height: 35px;
    width: 35px;
}
}
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: #663f08;
    color: white;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #663f08;
    box-shadow: 0 0 3px #8798ad;
    color: white;
}
.rmdp-wrapper{
    width: 100%;
    box-shadow: none;
}
/* .rmdp-wrapper div{
    width: 100%;
} */
.rmdp-calendar>div{
width:100%;
}

.rmdp-calendar {
    width: 100%;
}
.rmdp-day-picker>div{
width: 100%;
}
.rmdp-header {
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    margin: 10px 20px;
    width: auto !important;
}
.rmdp-day-picker{
    display: flex;
    width: 100%;
    padding: 0px 20px;
    margin-bottom: 15px;
}

.newsRight .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    background: transparent;
    border-radius: 8px;
    text-align: center;
}
.newsRight .wrapper .file-upload {
    height: 40px;
    width: 40px;
}
.newsRight .wrapper .file-upload input[type=file] {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
.logo_news {
    display: flex;
    align-items: center;
    width: 175px;
    justify-content: center;
    height: 50px;
    margin-bottom: 20px;
}
.logo_news img {
    height: 75px;
    object-fit: contain;
    width: 100%;
}
.highlight {
    background: #f3f3f3;
    box-shadow: 0 7px 8px rgb(0 0 0 / 15%);
    border: 3px solid #663f08;
}
.highlight_design{
    box-shadow: 2px 8px 15px rgb(0 0 0 / 20%);
}
.logo_news span{
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}
.logo_news h5{
    margin-left: 20px;
}
.upload_pdf{
    width: 200px;
    height: 45px;
    position: relative;
    background: #663f08;
    z-index: 1;
    border-radius: 5px;
}
.upload_pdf h2{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 15px;
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
    color: #fff;
    transform: translate(-50%,-50%);
    line-height: 45px;
    text-align: center;
    text-transform: capitalize;
}
.upload_pdf img{
    width: 25px;
    height: 25px;
    margin-left: 8px;
}
.upload_pdf input[type=file]{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 0px;
    outline: 0px;
}
.calculated_rates{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.calculated_rates h3{
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #515151;
    text-transform: capitalize;
}
.calculated_rates span{
    font-size: 16px;
    font-weight: 600;
    color: #663f08;
}

/* ############## shubham start ############################ */
.about_heros
{
    background-image: url('../img/hero_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 30px;
}
.about_heros img.heros_img
{
    width: 100%;
}
.heros_detail h5,
.celebrity_name h5
{
    font-style: italic;
    color: #707070;
    margin-bottom: 15px;
    font-size: 18px;
}
.heros_detail h2,
.celebrity_name h2
{
    color: #212121;
    font-size: 28px;
    font-weight: 500;
}
.about_heros .heros_detail p
{
    color: #707070;
    font-size: 16px;
    word-spacing: 1px;
}
.celebrity_main
{
    background-image: url('../img/celebrity_bg.png');
    background-size: cover;
    background-position: bottom;
    padding-bottom: 30px;
    height: 100%;
}
.celebrity_images img.back_bg{
    width: 100%;
    height: 0;
    min-height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
    filter: blur(2px);
}
.celebrity_images
{
    position: relative;
}
.cele_bg img.main_pro_img{
    position: absolute;
    left: 20px;
    width: 30%;
    top: 55%;
    border-radius: 68%;
    height: 0;
    min-height: 162px;
    object-fit: cover;
    border: 4px solid #fff;
}
.cle_bg{
    border: 5px solid #fff;
    border-radius: 10px 10px 0px 0px;
}
.celebrity_name
{
    padding-left: 37%;
    padding-top: 10px;
}
.about_celebrity
{
    padding: 5px 25px;
    color: #707070;
}
.about_celebrity p {
    letter-spacing: 1px;
    line-height: 23px;
    font-size: 14px;
}
.about_banner img
{
    width: 100%;
}
.about_pera1 p
{
    color: #707070;
    font-size: 16px;
    word-spacing: 1px;
    line-height: 28px;
    margin-bottom: 0px;
}
.our_mission
{
    background-image: url('../img/mission_bg.png');
    padding: 30px;
    background-size: cover;
    background-position: center;
}
.logoSlider .slick-prev
{
    left: 0px !important;
}
.logoSlider .slick-next
{
    right: 0px !important;
}
.inner_heading p {
    color: #1C2045;
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 600;
    line-height: 33px;
    font-family: 'Roboto';
}
.about_mission
{
    background-color: #fff;
    padding: 20px;
}
.team_1 img.member
{
    width: 100%;
}
.about_member
{
    text-align: center;
}
.about_member img {
    margin: 10px;
    width: 12%;
    cursor: pointer;
}
.social_media
{
    background: #fff;
    box-shadow: 0 3px 5px #0000002e;
    padding: 20px 0px;
    margin-top: 5px;
}
.team_1
{
    position: relative;
    margin-top: 30px;
}
.about_member
{
    position: absolute;
    width: 100%;
    top: 80%;
}
.social_media p
{
    font-size: 18px;
    letter-spacing: 1px;
}
.social_media h4
{
    font-size: 22px;
    letter-spacing: 1px;
}
.enquery_msg
{
  color: #2aca2a;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 0px;
}
.header_shadow
{
    box-shadow: 0 3px 5px #0000001c;
}
.about_pera p.question
{
    font-size: 20px;
    color: #202020;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 5px;
}
.aboutSection.commonPg.privacy_policy
{
    padding-bottom: 0px !important;
}
.successmsg.succes_smg
{
    margin-left: 15px;
    margin-bottom: 0px;
    font-weight: 500px;
    color: #41bb41;
}
.about_pera.join_us ul li {
    display: inline-block;
    color: #707070;
    font-size: 18px;
    margin-right: 50px;
    padding-top: 20px;
}
.join_aside {
    background-image: url('../img/join_us.png') ;
    border-radius: 5px;
    padding: 20px 0px;
    background-position: center;
    background-size: cover;
    height: 100%;
}
.contact-form.join_form input, 
.contact-form.join_form textarea,
.contact-form.join_form select
{
    color: #495057;
    font-size: 16px;
    border: 0px;
    min-height: 50px;
    font-weight: 400;
    transition: all 400ms ease-in-out;
    outline: 0px;
    padding: 10px 15px;
    border-radius: 5px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 5px #0000001f;
}
.form-group.set_reg_btn
{
    margin-bottom: 0px;
}
.contact-form.join_form .form-group.custom_fields label
{
    color: #0B1023;
    font-size: 18px;
    font-family: 'Roboto';
}
.contact-form.join_form .form-group.custom_fields textarea
{
    background-color: #fff;
    font-size: 16px;
    color: #707070;
    min-height: 80px;
    height: 0;
}
.feedback_bside
{
    background-image: url('../img/feedback.png') ;
    border-radius: 5px;
    padding: 20px 0px;
    background-position: center;
    background-size: cover;
    height: 100%;
}
.validationAlert {
    color: #dc3545!important;
    margin-bottom: -12px;
    font-size: 13px;
    font-weight: 300;
}
/* 13-07-21 */
.newsRight button:hover,
.format_design button:hover,
.upload_design_btn:hover,
.contact-form button:hover
{
    border: 2px solid #663f08;
    color: #663f08;
    background-color: transparent;
    transition: 0.5s;
}
.back_btn:hover
{
    background-color: #663f08;
    color: #fff;
}
.privacy_list {
    margin-left: 20px;
}
.cursor-pointer{
    cursor: pointer;
}
.image_selected_span{
    white-space: nowrap;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #212121;
}
/* ############### shubham end ########################## */

.popup-container {
    width: 320px;
    height: auto;
    position: fixed;
    top: 50%;
    background: transparent;
    transform: translate(0%,-50%);
    z-index: 999999;
    transition: .5s;
}
.close-section {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translate(0%,-50%);
    width: 35px;
    cursor: pointer;
}
.main-popup {
    width: 285px;
    height: auto;
    position: fixed;
    right: 0px;
    top: 50%;
    background: #fff;
    transform: translate(0%,-50%);
    box-shadow: 0px 3px 5px rgb(0 0 0 / 60%);
}
.show-popup{
    right: 0%;
}
.hide-popup{
    right: -285px; 
}
.popup-body{
    background: #fff;
}
.popup_header h2 {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: #663f08;
    margin: 10px auto;
}
.popup-content {
    text-align: center;
    margin-bottom: 15px;
}
.popup-content input, .popup-content select, .popup-content textarea {
    width: 90%;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
    color: #212121;
    padding: 4px;
    height: 30px;
    border: 1px solid #515151;
}
.popup-content input:focus, .popup-content select:focus, .popup-content textarea:focus{
    /* border: 1px solid #663f08; */
    outline: 1px solid #663f08;
} 
.popup-content button {
    width: 90%;
    border: 0px;
    outline: 0px;
    background: #663f08;
    color: #fff;
    padding: 5px;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
    margin-top: 10px;
}
.successEnquiry_msg {
    margin-left: 15px;
    font-weight: 400;
    color: #41bb41;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    margin-right: 15px;
}
.validationAlert2 {
    color: #dc3545!important;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 300;
    margin-top: -12px;
}

.whatsp_link{
    color: #1c3563 !important;
    text-decoration: none !important;
}
.whatsp_link_footer{
    color: #fff !important;
    text-decoration: none !important;
}
.normal_width {
    /* width: 60%; */
    margin: auto;
    text-align: center;
    border: 2px solid #663f08;
    padding: 5px;
    /* min-height: 300px; */
}
.normal_width h2 {
    font-size: 16px;
    color: #663f08;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 5px;
}
.adsize_w_h {
    display: flex;
    align-items: center;
    margin-left: 0px;
}
.width_height_normal{
    margin-right: 15px;
}
.adsize_w_h label {
    font-size: 16px;
    font-weight: 500;
    color: #515151;
}
.normal_ad_design_upload{
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}
.normal_ad_design_upload h5 {
    font-size: 16px;
    font-weight: 500;
    color: #515151;
    margin-bottom: 0px;
}
.adsize_w_h select{
    height: 30px;
    width: 150px;
    color: #495057;
    font-size: 14px;
    border: 0px;
    background: #fbfbfb;
    font-weight: 400;
    transition: all 400ms ease-in-out;
    outline: 0px;
    padding: 0px 15px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 15%);
}
.upload_normal_pdf {
    display: flex;
    margin-left: 20px;
    align-items: center;
}
.comb_normal{
    width: 100%;
    justify-content: space-between;
}
.select_ad_text h3{
    margin: 0;
    color: #313131;
    font-size: 20px;
    font-weight: 600;
}
.comb_text_align{
    display: flex;
}
.transaction_sec1{
    text-align: center;
}
.transaction_sec1 h5{
    font-size: 20px;
    color: #212121;
    font-weight: 500;
}
.transaction_sec1 p{
    font-size: 16px;
    color: #212121;
    font-weight: 400;
}
.trans_detail_sec {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
}
.trans_detail_sec h2{
    color: #212121;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-left: 6px;
}
.transs_div {
    background: #663f08;
    margin-bottom: 15px;
}
.transs_div h2{
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
    color: #fff;
    padding: 15px;
}
.transaction_sec2{
    margin-top: 25px;
    box-shadow: 0px 0px 3px #ccc;
    background: #fff;
    padding: 20px;
}
.col_width{
    width: 350px;
    color: #515151;
    font-size: 16px;
    font-weight: 500;
}
.table.transs_table td{
    font-size: 16px;
    font-weight: 400;
}
.color_text {
  color: #674009;
}

/* responsive part ----------------- */

@media screen and (max-width: 991px) {

.obituary_section{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 35px;
}
.about_pera1 p {
    color: #707070;
    font-size: 14px;
    word-spacing: 0px;
    line-height: 25px;
    margin-bottom: 0px;
}

.stepBG {
    /* background-image: url(../img/step_1.png); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 65px;
}
.stepsHead h2 {
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 10px;
    width: auto;
    text-align: center;
    color: #515151;
    text-decoration: none !important;
}
.newspaperListSec h2 {
    font-size: 18px;
    font-weight: 600;
    color: #515151;
    margin-bottom: 15px;
    line-height: 26px;
}

.newspaperList {
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
}
.logo_news {
    display: flex;
    align-items: center;
    width: 80px;
    height: 40px;
}
.newsPrice{
    width: 100%;
    margin-top: 15px;
}
.newsRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.newsRight button {
    border: 0px;
    outline: 0px !important;
    background: #663f08;
    padding: 0px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    height: 42px;
    margin-left: 25px;
    border: 2px solid #663f08;
    white-space: nowrap;
}
.color_mode h3 {
    margin: 0px;
    color: #313131;
    font-size: 16px;
    font-weight: 600;
}
.back_btn {
    border: 1px solid #663f08;
    outline: 0px !important;
    background: #fff;
    padding: 0px 30px;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: #663f08;
    border-radius: 5px;
    height: 42px;
    margin: 0px 10px;
}
.upload_design_btn {
    border: 1px solid #663f08;
    outline: 0px !important;
    background: #663f08;
    padding: 0px 10px;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    height: 42px;
    margin: 0px 10px;
    border: 2px solid #663f08;
}
.selected_dates {
    display: grid;
    grid-template-columns: auto auto;
}
.table-container{
    width: 100%;
    overflow-x: auto;
}
.custom_table {
    background: #fff;
    /* margin-top: 50px; */
    text-align: center;
    width: 800px;
}
.instruction_box {
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    text-align: center;
    margin-top: 15px;
    padding: 15px;
}
.color_mode {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.radio_btns {
    margin-left: 0px;
    margin-top: 10px;
}
.newspaperList h5 {
    font-size: 16px;
    font-weight: 500;
    color: #515151;
    margin-bottom: 10px;
}

}

@media screen and (max-width: 446px) {

.about_member{
    position: unset;
    width: 100%;
    margin-top: -75px;
}

.bg_blur {
    background: rgba(0,0,0,0.46);
    padding: 0px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}
.requirement_section .img_custom_height img {
    height: auto;
    width: 100%;
}
.commonHeadLight h2 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}
.commonHeadLight {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.requirement_detail{
    margin-left: 20px;
}
.requirement_detail h4 {
    color: #fff;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 13px;
}

.stepBG{
    /* background-image: url('../img/step_1.png') ; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 45px;
}

.newsRight h4 {
    margin: 0px;
    color: #663f08;
    font-weight: 600;
    font-size: 15px;
}


}